exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albercas-js": () => import("./../../../src/pages/albercas.js" /* webpackChunkName: "component---src-pages-albercas-js" */),
  "component---src-pages-calderas-js": () => import("./../../../src/pages/calderas.js" /* webpackChunkName: "component---src-pages-calderas-js" */),
  "component---src-pages-calentadores-solares-js": () => import("./../../../src/pages/calentadores-solares.js" /* webpackChunkName: "component---src-pages-calentadores-solares-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-iluminacion-led-js": () => import("./../../../src/pages/iluminacion-led.js" /* webpackChunkName: "component---src-pages-iluminacion-led-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingenieria-especializada-js": () => import("./../../../src/pages/ingenieria-especializada.js" /* webpackChunkName: "component---src-pages-ingenieria-especializada-js" */),
  "component---src-pages-paneles-solares-js": () => import("./../../../src/pages/paneles-solares.js" /* webpackChunkName: "component---src-pages-paneles-solares-js" */)
}

